.buttonSpinner {
  margin-right: 10px;
  display: inline-block;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(255, 255, 255, 0.27) rgba(255, 255, 255, 0.8) rgba(255, 255, 255, 0.8);
  animation: spinAnimation linear 2s infinite;
}

.formErrorMessage {
  @include unselectable;
  font-size: 14px;
  color: #ff7878;
  position: absolute;
  bottom: -25px;
  left: 0;
}

.formMessage {
  @include unselectable;
  display: flex;
  color: $form-message-color;
  position: absolute;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
  bottom: 0;
  left: 155px;
  height: 100%;

  @include for-size-large {
    left: 0;
    bottom: -32px;
    height: unset;
  }

  @include for-size-medium {
    bottom: -52px;
  }
}
