// Image element that should not be selectable by desktop cursor
@mixin unselectable() {
  user-select: none;
  -webkit-touch-callout: none;
}

@mixin soft-transition() {
  transition: all 0.3s;
}

@mixin visualy-hidden() {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

@mixin table-content() {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 40px auto 50px;
  box-shadow: 1px 11px 31px $shadow-color;
  border-radius: 5px;
  font-size: 0.9em;
  line-height: 1.6em;

  thead {
    border-bottom: 1px solid $divider-color;

    tr {
      background-color: $brand-light-gray;
    }
  }

  td,
  th {
    padding: 5px 10px;

    &:not(:last-child) {
      border-right: 1px solid $divider-color;
    }
  }

  th {
    text-align: left;
  }

  td {
    border-right: 1px solid $divider-color;
  }

  tbody tr:not(:last-child) {
    border-bottom: 1px solid $divider-color;
  }
}

@mixin article-content() {

  p,
  ol,
  ul {
    color: $article-content-color;
    font-size: 1.2rem;

    p {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }

  h4 {
    font-size: 1.3rem;
  }


  a {
    color: $link-color;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    margin: 40px auto 50px;
    box-shadow: 1px 11px 31px $shadow-color;
    border-radius: 5px;
  }

  table {
    @include table-content();
  }
}

@mixin conditional-bottom-margin($size: 80px) {
  &.withBottomMargin {
    margin-bottom: $size;
  }
}

@mixin use-predefined-background() {
  &.Yellow {
    background-color: $brand-yellow;
    color: $brand-dark-purple;
  }

  &.Transparent {
    color: $brand-dark-purple;
    background-color: transparent;
  }

  &.DarkPurple {
    background-color: $brand-dark-purple;
    color: #fff;
  }

  &.LightGray {
    background-color: $section-light-gray-background;
    color: $brand-dark-purple;
  }

  &.LightGrayPurple {
    background-color: $brand-pastel-purple;
    color: $brand-dark-purple;
  }

  &.LightPurple {
    background-color: $brand-saturated-purple;
    color: #fff;
  }

  &.LightPurpleGradient {
    background-color: $brand-saturated-purple;
    background-image: radial-gradient(ellipse at top, rgb(255 255 255 / 40%) 0%, rgb(255 255 255 / 18%) 27%, transparent 70%);
    background-size: 100% 40%;
    background-position-y: -30px;
    background-repeat: no-repeat;
  }

  &.White {
    background-color: #fff;
    color: $brand-dark-purple;
  }
}

@mixin word-wrap() {
  word-wrap: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
}