@import '../../../assets/global/main';

.Content404 {
  &__inner {
    @include standard-layout-column;
    padding: 150px;

    @include for-size-medium {
      padding: 150px 15px;
    }
  }

  &__div {
    font-weight: bold;
    text-align: center;
  }

  &__buttonHolder {
    margin: 50px auto;
    max-width: 300px;
  }
}
