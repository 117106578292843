@mixin standard-layout-column {
  margin: 0 auto;

  @media screen and (min-width: 767px) {
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
  }

  @media screen and (min-width: 992px) {
    max-width: $container-size-large;
  }

  @media screen and (min-width: 1200px) {
    max-width: $container-size-xtralarge;
  }

  @media screen and (min-width: 1250px) {
    max-width: $container-size;
  }
}

@mixin bloglist-layout-column {
  width: 100%;
  max-width: $blog-container-size;

  @include for-size-large() {
    max-width: 100%;
  }
}

@mixin article-layout-column {
  width: 100%;
  max-width: $container-size-large;

  @include for-size-large() {
    max-width: 100%;
  }
}

@mixin sidebar-layout-column {
  width: 100%;
  max-width: $sidebar-container-size;

  @include for-size-large() {
    max-width: 300px;
  }
}