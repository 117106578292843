$media-screen-small: 360px;
$media-screen-medium: 480px;
$media-screen-big: 768px;
$media-screen-large: 992px;
$media-screen-xtralarge: 1200px;

$media-screen-second-most-used-ga: 1536px;
$media-screen-the-most-used-ga: 1920px;

@mixin for-size-small() {
  @media (max-width: $media-screen-small - 1) {
    // 359px;
    @content;
  }
}

@mixin for-size-medium() {
  @media (max-width: $media-screen-medium - 1) {
    // 479px;
    @content;
  }
}

@mixin for-size-big() {
  @media (max-width: $media-screen-big - 1) {
    // 767px;
    @content;
  }
}

@mixin for-size-large() {
  @media (max-width: $media-screen-large - 1) {
    // 991px;
    @content;
  }
}

@mixin for-size-xtralarge() {
  @media (max-width: $media-screen-xtralarge - 1) {
    // 1199px;
    @content;
  }
}

@mixin for-size-second-most-used() {
  @media (max-width: $media-screen-second-most-used-ga) {
    // 1536px;
    @content;
  }
}